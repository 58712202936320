<template>
  <div class="payments-promotions">
    <div class="row">
      <div class="top-row w-100">
        <div class="menu-pill-container shadow-sm mx-auto">
          <div
            class="menu-pill"
            v-bind:class="activeTab === 0 ? 'active' : ''"
            @click="changeTab(0)"
          >
            Vendor Payments
          </div>
        </div>
      </div>
    </div>
    <b-tabs content-class="mt-3" align="center" v-model="activeTab">
      <b-tab :active="activeTab === 0">
        <template v-if="activeTab === 0">
          <vendor-payments />
        </template>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { mapState } from "vuex";
import VendorPayments from "@/view/pages/payments/pk/VendorPayments";
export default {
  components: {
    VendorPayments
  },
  data() {
    return {
      activeTab: 0,
      processPayment: false
    };
  },

  methods: {
    changeTab: function(index) {
      this.activeTab = index;
    }
  },
  computed: {
    ...mapState({
      region: state => state.region.isRegion,
      totalamount: function(state) {
        return state.payments.historyOutgoingPaymentsTotalAmount;
      }
    })
  }
};
</script>

<style lang="scss" scoped>
.payments-promotions {
  .top-row {
    z-index: 96;
    display: flex;
    position: relative;
    justify-content: flex-start;
    .menu-pill-container {
      border-radius: 50px;
      overflow: hidden;

      .menu-pill {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding: 15px;
        width: 150px;
        &.active {
          background: #dc1828;
          color: #fff;
        }
        color: #000;
      }
    }
  }
  .filt {
    // margin-left: 2%;
    margin-right: 24%;
    // margin-top: 20%;
    // left: 2%;
    // border: #333 solid;
    background: #ffffff;
    padding: 5px;
    font-size: 22px;
    font-weight: 400;
    border-radius: 5px;
  }

  ::v-deep .nav-tabs {
    display: none;
  }
  .amount-card {
    min-width: 190px;
    max-width: 10%;
    height: 175px;
    padding: 25px;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    // background: #74bbdb;
    .amount-icon {
      img {
        height: 40px;
        width: 50px;
      }
    }
    .amount-text {
      margin-top: 10px;
      text-align: center;
      height: 50px;
      color: #74bbdb;
      font-weight: 600;
    }
    .amount-text-value {
      margin-top: 10px;
      text-align: center;
      height: 50px;
      color: #000;
      font-size: 18px;
    }
    &.active {
      background: #2a3848;
      color: #fff;
      .amount-text-value,
      .amount-text {
        color: #fff;
      }
    }
  }
  .tab-active-back-color {
    background: #283848;
    .tab-items-color {
      color: #6dbcdb;
    }
  }
}
</style>
